import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import { useState } from "react";

export default function Footer() {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="top-shadow-sm mt-5 mb-3">
            <Modal
                centered
                show={showModal}
                // fullscreen="sm-down"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>კორპორაციული შეთავაზება</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    კომპანიებთან ვთანამშრომლობთ ჩამოთვლილიდან ერთ-ერთ ან
                    კომბინირებულ შეთავაზებებზე:
                    <ul className="mt-2">
                        <li>კომპანიის ავტოპარკის გარეცხვა თქვენს მისამართზე</li>
                        <li>
                            ბენეფიტი თანამშრომლებისთვის - ქარაფზე ფასდაკლება
                        </li>
                        <li>
                            ბენეფიტი მომხმარებლებისთვის - ქარაფზე ფასდაკლება
                        </li>
                    </ul>
                    <p>
                        <strong>
                            მეტი ინფორმაციისთვის და შეთავაზების მისაღებად
                            დაგვიკავშირდით: info@carup.ge / 574 180 111
                        </strong>
                    </p>
                </Modal.Body>
            </Modal>
            <Container>
                <DesktopFooter setShowModal={setShowModal} />
                <MobileFooter setShowModal={setShowModal} />
                <div className="keywords">
                    ქარაფი, CARUP, ავტომობილის გარეცხვა გამოძახებით, მანქანის
                    გარეცხვა გამოძახებით, ავტომობილის ადგილზე გარეცხვა, მანქანის
                    გარეცხვა გამოძახებით, მობილური სამრეცხაო თბილისში, მანქანის
                    ადგილზე გარეცხვა, სამრეცხაო შენს მისამართზე, პრემიუმ
                    ხარისხის მანქანის რეცხვის მომსახურება თბილისში გამოძახებით,
                    მანქანის რეცხვა, მანქანის რეცხვა გამოძახებით, მანქანის
                    გარეცხვა, ავტომობილის რეცხვა, ავტომობილის გარეცხვა,
                    სამრეცხაო, ლარიანი სამრეცხაო, კერხერით რეცხვა, შიგნიდან
                    რეცხვა, გარედან რეცხვა, შიგნიდან და გარედან რეცხვა, მანქანის
                    დითეილინგის მომსახურება, მანქანის გაწმენდის მომსახურება,
                    მანქანის ინტერიერის წმენდა, მანქანის ექსტერიერის წმენდა,
                    მანქანის პოლირება, მანქანის ეკო-მეგობრული გარეცხვა, მანქანის
                    ხელით რეცხვა, მანქანის სამრეცხაო თბილისში, მანქანის
                    კომფორტულად რეცხვა, მანქანის თვითრეცხვა, საუკეთესო სამრეცხაო
                    თბილისში, მანქანიდან ცხოველის ბეწვის მოცილება, მანქანის
                    დითეილინგი, კორპორაციული მანქანების სამრეცხაო, კორპორაციული
                    ავტომობილების სამრეცხაო, On-demand car wash in Tbilisi,
                    Mobile car wash service, Car wash near me, Mobile detailing
                    service, Car wash at home, Waterless car wash, Car wash app,
                    Car wash delivery, Car detailing on-demand, Car cleaning
                    service, Interior car cleaning, Exterior car wash, Car
                    waxing and polishing, Mobile car detailing, Deep clean car
                    service, Eco-friendly car wash, Express car wash service,
                    Hand car wash, Best car wash near me, Corporate car wash
                    services.
                </div>
            </Container>
        </div>
    );
}

function DesktopFooter({ setShowModal }) {
    const { t } = useTranslation();

    return (
        <Row className="d-none d-sm-none d-md-flex">
            <Col className="mt-3" sm={5}>
                <div className="mb-2 social-icons-container">
                    <Link
                        to="https://facebook.com/carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/facebook.svg"
                            alt="Facebook logo"
                        />
                    </Link>
                    <Link
                        to="https://m.me/101178736342121"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/messenger.svg"
                            alt="Messenger logo"
                        />
                    </Link>
                    <Link
                        to="https://www.youtube.com/@CARUP_OFFICIAL"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/youtube.svg"
                            alt="Youtube logo"
                        />
                    </Link>
                </div>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📞 574 180 111
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="mailto:info@carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📬 info@carup.ge
                    </Link>
                </p>
            </Col>
            <Col
                className="mt-3"
                xs={12}
                md={7}
                style={{
                    textAlign: "right",
                }}
            >
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    ყოველდღე 10:00 - 19:00
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="#" onClick={() => setShowModal(true)}>
                        კორპორაციული შეთავაზება
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link
                        to="/terms-and-conditions.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("terms-and-conditions")}
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="/privacy-policy">{t("privacy-policy")}</Link>
                </p>
            </Col>
        </Row>
    );
}

function MobileFooter({ setShowModal }) {
    const { t } = useTranslation();

    return (
        <Row className="d-flex d-md-none text-center">
            <Col className="mt-3">
                <div className="mb-2 social-icons-mobile-container">
                    <Link
                        to="https://facebook.com/carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/facebook.svg"
                            alt="Facebook logo"
                        />
                    </Link>
                    <Link
                        to="https://m.me/101178736342121"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/messenger.svg"
                            alt="Messenger logo"
                        />
                    </Link>
                    <Link
                        to="https://www.youtube.com/@CARUP_OFFICIAL"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/youtube.svg"
                            alt="Youtube logo"
                        />
                    </Link>
                </div>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📞 574 180 111
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="mailto:info@carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📬 info@carup.ge
                    </Link>
                </p>
                <hr
                    style={{
                        margin: "unset",
                    }}
                />
                <p
                    className="mb-1 mt-3"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    ყოველდღე 10:00 - 19:00
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="#" onClick={() => setShowModal(true)}>
                        კორპორაციული შეთავაზება
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link
                        to="/terms-and-conditions.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("terms-and-conditions")}
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="/privacy-policy">{t("privacy-policy")}</Link>
                </p>
            </Col>
        </Row>
    );
}
