import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useAuth } from "../../contexts/Authentication";
import { COLORS } from "../../constants";
import "./style.css";

export default function Header() {
    const { isAuthenticated, removeToken, userData } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const offCanvasRef = useRef();
    const closeOffCanvas = () => offCanvasRef.current.backdrop.click();

    const [showInstallButton, setShowInstallButton] = useState(false);
    const [installPrompt, setInstallPrompt] = useState();

    useEffect(() => {
        const handleInstallPrompt = (e) => {
            e.preventDefault();
            setShowInstallButton(true);
            setInstallPrompt(e);
        };

        window.addEventListener("beforeinstallprompt", (e) =>
            handleInstallPrompt(e)
        );

        return () => {
            window.removeEventListener("beforeinstallprompt", (e) =>
                handleInstallPrompt(e)
            );
        };
    }, []);

    const displayInstallPrompt = () => {
        setShowInstallButton(false);
        installPrompt.prompt();
        setInstallPrompt(null);
    };

    return (
        <div className="shadow-sm mb-5">
            <Navbar expand={false} collapseOnSelect>
                <Container>
                    <Link to="/">
                        <img
                            className="logo"
                            src="/images/logo.svg"
                            alt="Logo"
                        />
                    </Link>

                    <div>
                        {isAuthenticated ? (
                            userData?.firstName ? (
                                <Button
                                    // className="d-none d-sm-inline"
                                    variant="outline-secondary"
                                    size="md"
                                    disabled={true}
                                    // onClick={() => {
                                    //     navigate("/profile");
                                    // }}
                                    style={{
                                        // backgroundColor:
                                        //     COLORS.PRIMARY.TURQOISE,
                                        borderColor: COLORS.PRIMARY.BLUE,
                                        color: COLORS.PRIMARY.BLUE,
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        marginRight: 15,
                                        opacity: 1,
                                    }}
                                >
                                    {userData?.firstName}
                                </Button>
                            ) : (
                                <Button
                                    className="d-none d-sm-inline"
                                    variant="primary"
                                    size="md"
                                    onClick={() => {
                                        navigate("/order");
                                    }}
                                    style={{
                                        backgroundColor:
                                            COLORS.PRIMARY.TURQOISE,
                                        borderColor: COLORS.PRIMARY.TURQOISE,
                                        marginRight: 15,
                                    }}
                                >
                                    {t("header.order")}
                                </Button>
                            )
                        ) : (
                            <Button
                                variant="primary"
                                size="md"
                                onClick={() => {
                                    navigate("/login");
                                }}
                                style={{
                                    backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    borderColor: COLORS.PRIMARY.TURQOISE,
                                    marginRight: 15,
                                }}
                            >
                                {t("header.login")}
                            </Button>
                        )}

                        <Navbar.Toggle
                            aria-controls={`offcanvasNavbar-expand-false`}
                            style={{
                                borderColor: COLORS.PRIMARY.BLUE,
                            }}
                        />
                    </div>

                    <Navbar.Offcanvas
                        ref={offCanvasRef}
                        id={`offcanvasNavbar-expand-false`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Button
                                variant="primary"
                                size="md"
                                onClick={() => {
                                    navigate("/order");
                                    closeOffCanvas();
                                }}
                                style={{
                                    backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    borderColor: COLORS.PRIMARY.TURQOISE,
                                    marginRight: 15,
                                }}
                            >
                                {t("header.order")}
                            </Button>
                            {showInstallButton && (
                                <Button
                                    id="install-button"
                                    variant="primary"
                                    size="md"
                                    onClick={displayInstallPrompt}
                                    style={{
                                        backgroundColor: COLORS.PRIMARY.BLUE,
                                        borderColor: COLORS.PRIMARY.BLUE,
                                    }}
                                >
                                    ჩამოტვირთე ⬇️
                                </Button>
                            )}
                            {/* <Stack direction="horizontal" gap={4}>
                        <Nav.Link
                            className={[
                                "language",
                                i18n.language === "ka" ? "active" : null,
                            ]}
                            onClick={() => i18n.changeLanguage("ka")}
                        >
                            ქართული
                        </Nav.Link>
                        <Nav.Link
                            className={[
                                "language",
                                i18n.language === "en" ? "active" : null,
                            ]}
                            onClick={() => i18n.changeLanguage("en")}
                        >
                            English
                        </Nav.Link>
                    </Stack> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Nav.Link
                                    to="/"
                                    as={NavLink}
                                    onClick={() => closeOffCanvas()}
                                >
                                    {t("header.home")}
                                </Nav.Link>
                                {!isAuthenticated ? (
                                    <>
                                        <Nav.Link
                                            to="/login"
                                            as={NavLink}
                                            onClick={() => closeOffCanvas()}
                                        >
                                            {t("header.login")}
                                        </Nav.Link>
                                    </>
                                ) : null}
                                {isAuthenticated ? (
                                    <>
                                        {/* <Nav.Link
                                            onClick={() => {
                                                navigate("/profile");
                                                closeOffCanvas();
                                            }}
                                        >
                                            {t("header.profile")}
                                        </Nav.Link> */}
                                        <Nav.Link
                                            to="/order"
                                            as={NavLink}
                                            onClick={() => closeOffCanvas()}
                                        >
                                            {t("header.order")}
                                        </Nav.Link>
                                        <Nav.Link
                                            to="/orders"
                                            as={NavLink}
                                            onClick={() => closeOffCanvas()}
                                        >
                                            {t("header.orders")}
                                        </Nav.Link>
                                        <hr className="rounded" />
                                        <Nav.Link
                                            onClick={() => {
                                                removeToken();
                                                navigate("/");
                                                closeOffCanvas();
                                            }}
                                            style={{
                                                color: COLORS.ADDITIONAL.RED,
                                            }}
                                        >
                                            {t("header.logout")}
                                        </Nav.Link>
                                    </>
                                ) : null}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}
