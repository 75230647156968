import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useAuth } from "../contexts/Authentication";

export default function PaymentMethod({ props: { savedCard, updateForm } }) {
    const { updateProfile } = useAuth();

    async function deleteSavedCard() {
        const warning = window.confirm(
            "ნამდვილად გსურს დამახსოვრებული ბარათის წაშლა?"
        );
        if (!warning) return;

        await updateProfile(undefined, { savedCard: null });
    }

    if (savedCard) {
        return (
            <ListGroup className="mb-2">
                <ListGroup.Item
                    action
                    active={true}
                    type="button"
                    className="d-flex justify-content-between align-items-start"
                >
                    <img
                        src="/images/debit-card.png"
                        style={{
                            height: "50px",
                            marginRight: "8px",
                        }}
                        alt="Debit card"
                    />
                    <div className="ms-2 me-auto">
                        <div>დამახსოვრებული ბარათით გადახდა</div>
                        <div className="fw-bold">{savedCard}</div>
                    </div>
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Link
                            to="#"
                            onClick={() => deleteSavedCard()}
                            style={{
                                fontSize: "14px",
                            }}
                        >
                            წაშლა
                        </Link>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        );
    }
    if (!savedCard && updateForm) {
        return (
            <Form.Check className="mb-3" type="checkbox" id="save-card">
                <Form.Check.Input
                    type="checkbox"
                    onChange={(e) => {
                        updateForm("saveCard", e.target.checked);
                    }}
                />
                <Form.Check.Label>
                    ბარათის დამახსოვრება მარტივი გადახდებისთვის
                </Form.Check.Label>
            </Form.Check>
        );
    }
}
