import "./style.css";

export default function Loader({ godMode }) {
    return (
        <div
            className="loader-container"
            style={
                !godMode
                    ? {
                          height: "auto",
                      }
                    : null
            }
        >
            <div className="loader-spring">
                <span className="loader-bubble loader-bubble1"></span>
                <span className="loader-bubble loader-bubble2"></span>
                <span className="loader-bubble loader-bubble3"></span>
                <span className="loader-bubble loader-bubble4"></span>
                <span className="loader-bubble loader-bubble5"></span>
            </div>
            <img className="loader-logo" src="/images/logo.svg" alt="Logo" />
        </div>
    );
}
