import { useState } from "react";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { BASE_URL, COLORS, NUMERIC_REGEX } from "../../constants";
import { useAuth } from "../../contexts/Authentication";
import { useToasts } from "react-bootstrap-toasts";

export default function Login() {
    const toasts = useToasts();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, saveToken } = useAuth();

    const from = location.state?.from?.pathname || "/";

    const [disableActions, setDisableActions] = useState(false);
    const [disableSendButton, setDisableSendButton] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState(undefined);

    // if (isAuthenticated) return <Navigate to="/order" />;
    if (isAuthenticated)
        return (
            <Navigate
                to={
                    from && from.includes("order/") && from.length === 31
                        ? from
                        : "/order"
                }
            />
        );

    async function sendVerificationCode(event) {
        try {
            event?.preventDefault();
            if (!phoneNumber?.length)
                return alert("ტელ. ნომრის ჩაწერა აუცილებელია");

            setDisableActions(true);
            const response = await (
                await fetch(`${BASE_URL}/authenticate`, {
                    method: "POST",
                    body: JSON.stringify({
                        phoneNumber: `+995${phoneNumber}`,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
            ).json();

            if (!response?.success && response?.error) {
                return alert(response.error);
            }
            setDisableSendButton(true);
            setVerificationCode("");
            setTimeout(() => {
                setDisableSendButton(false);
            }, 60_000);
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setDisableActions(false);
        }
    }

    async function checkVerificationCode(event) {
        try {
            event?.preventDefault();

            if (!phoneNumber?.length)
                return alert("ტელ. ნომრის ჩაწერა აუცილებელია");

            if (!verificationCode?.length)
                return alert("ვერიფიკაციის კოდი უნდა შედგებოდეს 4 ციფრისგან");

            setDisableActions(true);
            const response = await (
                await fetch(`${BASE_URL}/authenticate`, {
                    method: "POST",
                    body: JSON.stringify({
                        phoneNumber: `+995${phoneNumber}`,
                        verificationCode,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
            ).json();

            if (!response?.success && response?.error) {
                return alert(response.error);
            }
            if (response?.data?.token) {
                saveToken(response.data.token);
                // Send them back to the page they tried to visit when they were
                // redirected to the login page. Use { replace: true } so we don't create
                // another entry in the history stack for the login page.  This means that
                // when they get to the protected page and click the back button, they
                // won't end up back on the login page, which is also really nice for the
                // user experience.
                navigate(from, { replace: true });
            }
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setDisableActions(false);
        }
    }

    return (
        <div>
            <Container className="mt-5">
                <Row className="mb-3">
                    <Col></Col>
                    <Col xs={10} sm={8} md={8}>
                        <h2 className="mb-4 fw-bold text-center">
                            {t("login.title")}
                        </h2>
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={10} sm={10} md={6} lg={4}>
                        <Form
                            onSubmit={
                                verificationCode === undefined &&
                                phoneNumber?.length === 9
                                    ? sendVerificationCode
                                    : phoneNumber?.length === 9 &&
                                      verificationCode?.length === 4
                                    ? checkVerificationCode
                                    : (event) => event.preventDefault()
                            }
                        >
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    {t("login.phone-number")}
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">
                                        +995
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="tel"
                                        aria-label={t("login.phone-number")}
                                        onChange={(event) =>
                                            setPhoneNumber(
                                                event.target.value.replace(
                                                    NUMERIC_REGEX,
                                                    ""
                                                )
                                            )
                                        }
                                        value={phoneNumber}
                                    />
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    შესვლის კოდი გამოიგზავნება SMS-ით
                                </Form.Text>
                            </Form.Group>

                            <Button
                                disabled={
                                    phoneNumber?.length !== 9 ||
                                    disableSendButton
                                }
                                className="mb-3"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.BLUE,
                                    borderColor: COLORS.PRIMARY.BLUE,
                                }}
                                onClick={() => sendVerificationCode()}
                            >
                                კოდის გაგზავნა
                            </Button>
                            <hr className="rounded" />

                            {verificationCode !== undefined ? (
                                <>
                                    <Form.Group className="mb-2">
                                        <Form.Label>მიღებული კოდი</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={verificationCode}
                                            onChange={(event) =>
                                                setVerificationCode(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <div className="mt-3 d-grid gap-2 shadow-sm">
                                        <Button
                                            disabled={
                                                verificationCode?.length !==
                                                    4 || disableActions
                                            }
                                            onClick={() =>
                                                checkVerificationCode()
                                            }
                                            size="lg"
                                            type="submit"
                                            style={{
                                                backgroundColor:
                                                    COLORS.PRIMARY.TURQOISE,
                                                borderColor:
                                                    COLORS.PRIMARY.TURQOISE,
                                            }}
                                        >
                                            {t("login.login")}
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </Form>
                        <p style={{ fontSize: "12px" }}>
                            * შესვლის ღილაკზე დაჭერით ავტომატურად ეთანხმები
                            ჩვენს{" "}
                            <Link to="/privacy-policy">
                                წესებსა და პირობებს
                            </Link>
                        </p>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
}
