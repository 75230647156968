import "./style.css";

export default function Stepper({ content, isVertical }) {
    return (
        <div className="stepper-wrapper">
            <div style={{ display: isVertical ? "flex" : "block" }}>
                <div
                    className={`stepper-head ${
                        isVertical ? "vertical-stepper-head" : ""
                    }`}
                >
                    {content.map((el, i) => (
                        <Step
                            key={i}
                            index={i}
                            isActive={el.isActive}
                            isComplete={el.isComplete}
                            isWarning={el.isWarning}
                            isError={el.isError}
                            indicator={i + 1}
                            label={el.label}
                            comment={el.comment}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function Step({
    indicator,
    label,
    comment,
    isActive,
    isComplete,
    isWarning,
    isError,
}) {
    const classes = [""];
    if (isActive) {
        classes.push("is-active");
    }
    if (isComplete) {
        classes.push("is-complete");
    }
    if (isWarning) {
        classes.push("is-warning");
    }
    if (isError) {
        classes.push("is-error");
    }

    return (
        <div className={`stepper-step ${classes.join(" ")}`}>
            <div className="stepper-indicator">
                <span className="stepper-indicator-info">
                    {isComplete ? (
                        <svg
                            className="stepper-tick"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 490 490"
                        >
                            <path d="M452.253 28.326L197.831 394.674 29.044 256.875 0 292.469l207.253 169.205L490 54.528z" />
                        </svg>
                    ) : (
                        indicator
                    )}
                </span>
            </div>
            <div className="stepper-label">
                {label}
                {comment ? (
                    <span style={{ color: "#000", fontSize: "12px" }}>
                        {" "}
                        - {comment}
                    </span>
                ) : null}
            </div>
        </div>
    );
}
