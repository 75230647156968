import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ka } from "date-fns/locale/ka";
import {
    ALPHANUMERIC_REGEX,
    BASE_URL,
    COLORS,
    VEHICLES,
    VEHICLES_ARRAY,
    TIME_SLOTS,
} from "../../constants";
import { useAuth } from "../../contexts/Authentication";
import "./style.css";
import { useToasts } from "react-bootstrap-toasts";
import PaymentMethod from "../../components/PaymentMethod";
import { OrderPackage } from "../Home/Packages";
registerLocale("ka", ka);

export default function Order() {
    const toasts = useToasts();
    const { userData, token, services } = useAuth();
    const navigate = useNavigate();
    const detectedServiceId = new URLSearchParams(window.location.search).get(
        "serviceId"
    );

    const [disableActions, setDisableActions] = useState(false);
    const [isErrorVisible, setErrorVisible] = useState(false);
    const [busyDates, setBusyDates] = useState([]);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const setCalendarDate = (date) => {
        const selectedDate = new Date(date);
        const fixedDate = new Date(
            Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
            )
        )
            .toISOString()
            .slice(0, 10);

        setIsCalendarOpen(!isCalendarOpen);
        updateForm("date", fixedDate);
    };
    const toggleCalendar = (event) => {
        event.preventDefault();
        setIsCalendarOpen(!isCalendarOpen);
    };
    const [form, setForm] = useState({
        make: [],
        model: [],
        licensePlate: "",
        date: "",
        time: "",
        address: "",
        comment: "",
        firstName: "",
        lastName: "",
        email: "",
        serviceId: detectedServiceId || services?.[0]?._id || "",
        source: "WEB",
        saveCard: false,
        acceptedTerms: false,
    });
    const [hiddenFields, setHiddenFields] = useState({
        firstName: !!(userData?.firstName || false),
        lastName: !!(userData?.lastName || false),
        email: !!(userData?.email || false),
    });

    function updateForm(field, value) {
        setForm((prevForm) => {
            return {
                ...prevForm,
                [field]: value,
            };
        });
    }

    useEffect(() => {
        fetchRecentOrder();
    }, []);

    async function fetchRecentOrder() {
        try {
            const response = await (
                await fetch(`${BASE_URL}/order/recent`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();
            if (response?.success && response?.data) {
                if (response.data?.busyDates) {
                    setBusyDates(response.data.busyDates);
                }
                setHiddenFields({
                    firstName: !!(
                        userData?.firstName ||
                        // response.data?.firstName ||
                        false
                    ),
                    lastName: !!(
                        userData?.lastName ||
                        // response.data?.lastName ||
                        false
                    ),
                    email: !!(
                        userData?.email ||
                        // response.data?.email ||
                        false
                    ),
                });

                const newForm = {
                    ...form,
                };
                if (response.data?.make) newForm.make = [response.data.make];
                if (response.data?.model) newForm.model = [response.data.model];
                if (response.data?.licensePlate)
                    newForm.licensePlate = response.data.licensePlate;
                if (response.data?.address)
                    newForm.address = response.data.address;
                if (response.data?.comment)
                    newForm.comment = response.data.comment;
                setForm(newForm);
                // setForm({
                //     ...form,
                //     make: [response.data.make],
                //     model: [response.data.model],
                //     licensePlate: response.data.licensePlate,

                //     date: "",
                //     time: "",
                //     address: response.data.address,
                //     comment: response.data.comment,

                //     source: "WEB",
                //     saveCard: false,
                //     acceptedTerms: false,
                // });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function submit(event) {
        try {
            event?.preventDefault();

            const isSomethingMissing =
                !form.make?.length ||
                !form.model?.length ||
                !form.licensePlate?.length ||
                !form.date?.length ||
                !form.time?.length ||
                !form.address?.length ||
                !form.acceptedTerms;

            // Display error
            if (isSomethingMissing) {
                setErrorVisible(true);

                // If error happened in the upper section, then scroll to top
                if (
                    !form.make?.length ||
                    !form.model?.length ||
                    !form.licensePlate?.length ||
                    !form.date?.length ||
                    !form.time?.length ||
                    !form.address?.length
                ) {
                    window.scrollTo(0, 0);
                }

                return;
            }

            setDisableActions(true);

            // ${i18n.language || "ka"}
            const response = await (
                await fetch(`${BASE_URL}/order?lang=ka`, {
                    method: "POST",
                    body: JSON.stringify(form),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();

            if (!response?.success && response?.error) {
                return alert(response.error);
            }

            // Redirect to payments or order details page
            if (response?.data) {
                if (response?.data?.paymentUrl) {
                    window.location.href = response.data?.paymentUrl;
                    return;
                } else {
                    return navigate(`/order/${response.data?.orderId}`);
                }
            }
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setDisableActions(false);
        }
    }

    // Unset time when it's busy
    useEffect(() => {
        if (isBusy(form.date, form.time)) {
            updateForm("time", undefined);
        }
    }, [form]);

    function isBusy(date, time) {
        if (!date || !time) return false;
        const isToday = new Date(date).getDate() === new Date().getDate();
        const isBusyBooked = busyDates.find(
            (busy) => busy.date === date && busy.time.includes(time)
        );
        if (!isToday) return isBusyBooked;

        let isBusyTodayTime = false;
        TIME_SLOTS.forEach((item) => {
            if (
                item.value === time &&
                (new Date().getHours() > item.disabledAfter || isBusyBooked)
            ) {
                isBusyTodayTime = true;
            }
        });

        return isBusyTodayTime;
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <h2 className="mb-4 fw-bold text-center">
                            შეავსე გამოძახების ფორმა
                        </h2>

                        <Card bg="light" text="dark" className="mb-3">
                            {/* <Alert variant="info" className="text-center">
                                <strong>
                                    ⚠️ ამ ეტაპზე მომსახურებით სარგებლობა
                                    შესაძლებელია: ვაკე, ვერა, მთაწმინდა და
                                    მიმდებარე ტერიტორიებზე.
                                </strong>
                            </Alert> */}
                            <Card.Body>
                                <Form onSubmit={submit}>
                                    {/* Vehicle make (required) */}
                                    <Typeahead
                                        id="make"
                                        emptyLabel="ამ დასახელებით არაფერი მოიძებნა"
                                        onChange={(selected) => {
                                            updateForm("make", selected);
                                            updateForm("model", []);
                                        }}
                                        options={VEHICLES_ARRAY || []}
                                        selected={form.make}
                                        renderInput={({
                                            inputRef,
                                            referenceElementRef,
                                            ...inputProps
                                        }) => (
                                            <FloatingLabel
                                                className="mb-3"
                                                controlId="make"
                                                label="მწარმოებელი"
                                                style={{
                                                    color:
                                                        isErrorVisible &&
                                                        !form.make?.length
                                                            ? COLORS.ADDITIONAL
                                                                  .RED
                                                            : null,
                                                }}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    onChange={(event) => {
                                                        updateForm(
                                                            "make",
                                                            event.target.value
                                                        );
                                                        updateForm("model", []);
                                                    }}
                                                    value={form.make}
                                                    {...inputProps}
                                                    ref={(input) => {
                                                        inputRef(input);
                                                        referenceElementRef(
                                                            input
                                                        );
                                                    }}
                                                    placeholder="მწარმოებელი"
                                                    style={{
                                                        color:
                                                            isErrorVisible &&
                                                            !form.make?.length
                                                                ? COLORS
                                                                      .ADDITIONAL
                                                                      .RED
                                                                : null,
                                                    }}
                                                />
                                            </FloatingLabel>
                                        )}
                                    />

                                    {/* Vehicle model (required) */}
                                    <Typeahead
                                        id="model"
                                        emptyLabel="ამ დასახელებით არაფერი მოიძებნა"
                                        onChange={(selected) => {
                                            updateForm("model", selected);
                                        }}
                                        options={
                                            form.make
                                                ? VEHICLES[form.make] || []
                                                : []
                                        }
                                        selected={form.model}
                                        renderInput={({
                                            inputRef,
                                            referenceElementRef,
                                            ...inputProps
                                        }) => (
                                            <FloatingLabel
                                                className="mb-3"
                                                controlId="model"
                                                label="მოდელი"
                                                style={{
                                                    color:
                                                        isErrorVisible &&
                                                        !form.model?.length
                                                            ? COLORS.ADDITIONAL
                                                                  .RED
                                                            : null,
                                                }}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    // onChange={(event) => {
                                                    //     updateForm(
                                                    //         "model",
                                                    //         event.target.value
                                                    //     );
                                                    // }}
                                                    value={form.model}
                                                    {...inputProps}
                                                    ref={(input) => {
                                                        inputRef(input);
                                                        referenceElementRef(
                                                            input
                                                        );
                                                    }}
                                                    placeholder="მოდელი"
                                                    style={{
                                                        color:
                                                            isErrorVisible &&
                                                            !form.model?.length
                                                                ? COLORS
                                                                      .ADDITIONAL
                                                                      .RED
                                                                : null,
                                                    }}
                                                />
                                            </FloatingLabel>
                                        )}
                                    />
                                    {/* License plate (required) */}
                                    <FloatingLabel
                                        className="mb-4"
                                        controlId="licensePlate"
                                        label="სახელმწიფო სანომრე ნიშანი"
                                        style={{
                                            color:
                                                isErrorVisible &&
                                                !form.licensePlate?.length
                                                    ? COLORS.ADDITIONAL.RED
                                                    : null,
                                        }}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="სახელმწიფო სანომრე ნიშანი"
                                            onChange={(event) =>
                                                updateForm(
                                                    "licensePlate",
                                                    event.target.value
                                                        .replace(
                                                            ALPHANUMERIC_REGEX,
                                                            ""
                                                        )
                                                        .toUpperCase()
                                                )
                                            }
                                            value={form.licensePlate}
                                        />
                                    </FloatingLabel>

                                    {/* Date (required) */}
                                    <FloatingLabel
                                        className="mb-3"
                                        controlId="date"
                                        label="გარეცხვის დღე"
                                        style={{
                                            color:
                                                isErrorVisible &&
                                                !form.date?.length
                                                    ? COLORS.ADDITIONAL.RED
                                                    : null,
                                        }}
                                    >
                                        <Form.Control
                                            type="text"
                                            readOnly
                                            placeholder="გარეცხვის დღე"
                                            onClick={(e) => toggleCalendar(e)}
                                            value={form.date}
                                        />
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {isCalendarOpen && (
                                                <DatePicker
                                                    locale="ka"
                                                    inline
                                                    selected={form.date}
                                                    onChange={setCalendarDate}
                                                    minDate={new Date()}
                                                    excludeDates={busyDates
                                                        .filter(
                                                            (item) =>
                                                                item.time
                                                                    .length ===
                                                                3
                                                        )
                                                        .map(
                                                            (item) =>
                                                                new Date(
                                                                    item.date
                                                                )
                                                        )}
                                                />
                                            )}
                                        </div>
                                    </FloatingLabel>

                                    {/* Time (required) */}
                                    <FloatingLabel
                                        className="mb-3"
                                        controlId="time"
                                        label="გარეცხვის დრო"
                                    >
                                        <Form.Select
                                            aria-label="გარეცხვის დრო"
                                            value={form.time}
                                            disabled={!form.date?.length}
                                            onChange={(event) =>
                                                updateForm(
                                                    "time",
                                                    event.currentTarget.value
                                                )
                                            }
                                            style={{
                                                color:
                                                    isErrorVisible &&
                                                    !form.time?.length
                                                        ? COLORS.ADDITIONAL.RED
                                                        : null,
                                            }}
                                        >
                                            <option value="">
                                                აირჩიე სასურველი დროის მონაკვეთი
                                            </option>
                                            {TIME_SLOTS.map((item) => (
                                                <option
                                                    key={item.value}
                                                    value={
                                                        isBusy(
                                                            form.date,
                                                            item.value
                                                        )
                                                            ? undefined
                                                            : item.value
                                                    }
                                                    disabled={isBusy(
                                                        form.date,
                                                        item.value
                                                    )}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>

                                    {/* Address (required) */}
                                    <FloatingLabel
                                        className="mb-3"
                                        controlId="address"
                                        label="მისამართი"
                                        style={{
                                            color:
                                                isErrorVisible &&
                                                !form.address?.length
                                                    ? COLORS.ADDITIONAL.RED
                                                    : null,
                                        }}
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="ავტომობილის ადგილმდებარეობა (მისამართი)"
                                            onChange={(event) =>
                                                updateForm(
                                                    "address",
                                                    event.target.value
                                                )
                                            }
                                            value={form.address}
                                        />
                                    </FloatingLabel>

                                    {/* Comment */}
                                    <FloatingLabel
                                        className="mb-3"
                                        controlId="comment"
                                        label="ზუსტი ადგილმდებარეობა / მინიშნება"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            onChange={(event) =>
                                                updateForm(
                                                    "comment",
                                                    event.target.value
                                                )
                                            }
                                            value={form.comment}
                                            style={{ height: "80px" }}
                                        />
                                    </FloatingLabel>

                                    {/* First name (required) */}
                                    {!hiddenFields.firstName ? (
                                        <FloatingLabel
                                            className="mb-3"
                                            controlId="firstName"
                                            label="სახელი"
                                            style={{
                                                color:
                                                    isErrorVisible &&
                                                    !form.firstName?.length
                                                        ? COLORS.ADDITIONAL.RED
                                                        : null,
                                            }}
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="სახელი"
                                                onChange={(event) =>
                                                    updateForm(
                                                        "firstName",
                                                        event.target.value
                                                    )
                                                }
                                                value={form.firstName}
                                            />
                                        </FloatingLabel>
                                    ) : null}

                                    {/* Last name (required) */}
                                    {!hiddenFields.lastName ? (
                                        <FloatingLabel
                                            className="mb-3"
                                            controlId="lastName"
                                            label="გვარი"
                                            style={{
                                                color:
                                                    isErrorVisible &&
                                                    !form.lastName?.length
                                                        ? COLORS.ADDITIONAL.RED
                                                        : null,
                                            }}
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="გვარი"
                                                onChange={(event) =>
                                                    updateForm(
                                                        "lastName",
                                                        event.target.value
                                                    )
                                                }
                                                value={form.lastName}
                                            />
                                        </FloatingLabel>
                                    ) : null}

                                    {/* Email */}
                                    {!hiddenFields.email ? (
                                        <FloatingLabel
                                            className="mb-3"
                                            controlId="email"
                                            label="ელ. ფოსტა"
                                        >
                                            <Form.Control
                                                type="email"
                                                placeholder="ელ. ფოსტა"
                                                onChange={(event) =>
                                                    updateForm(
                                                        "email",
                                                        event.target.value
                                                    )
                                                }
                                                value={form.email}
                                            />
                                        </FloatingLabel>
                                    ) : null}

                                    <OrderPackage
                                        isSelected={
                                            form.serviceId ===
                                            services?.[0]?._id
                                        }
                                        select={() =>
                                            updateForm(
                                                "serviceId",
                                                services?.[0]?._id
                                            )
                                        }
                                        className="mb-3"
                                        data={services?.[0]}
                                    />
                                    <OrderPackage
                                        isSelected={
                                            form.serviceId ===
                                            services?.[1]?._id
                                        }
                                        select={() =>
                                            updateForm(
                                                "serviceId",
                                                services?.[1]?._id
                                            )
                                        }
                                        className="mb-3"
                                        data={services?.[1]}
                                    />
                                    <OrderPackage
                                        isSelected={
                                            form.serviceId ===
                                            services?.[2]?._id
                                        }
                                        select={() =>
                                            updateForm(
                                                "serviceId",
                                                services?.[2]?._id
                                            )
                                        }
                                        className="mb-3"
                                        data={services?.[2]}
                                    />

                                    {/* Payment method */}
                                    <PaymentMethod
                                        props={{
                                            savedCard: userData?.savedCard,
                                            updateForm,
                                        }}
                                    />

                                    {/* Terms and conditions checkbox (required) */}
                                    <Form.Check
                                        className="mb-3"
                                        type="checkbox"
                                        id="accept-terms"
                                    >
                                        <Form.Check.Input
                                            style={{
                                                borderColor:
                                                    isErrorVisible &&
                                                    !form.acceptedTerms
                                                        ? COLORS.ADDITIONAL.RED
                                                        : null,
                                            }}
                                            type="checkbox"
                                            onChange={(e) => {
                                                updateForm(
                                                    "acceptedTerms",
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <Form.Check.Label
                                            style={{
                                                color:
                                                    isErrorVisible &&
                                                    !form.acceptedTerms
                                                        ? COLORS.ADDITIONAL.RED
                                                        : null,
                                            }}
                                        >
                                            ვეთანხმები{" "}
                                            <Link
                                                to="/terms-and-conditions.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="href-underline"
                                            >
                                                წესებსა და პირობებს
                                            </Link>
                                        </Form.Check.Label>
                                    </Form.Check>

                                    {/* Submit button */}
                                    <div className="d-grid gap-2 mb-1 shadow-sm">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            type="submit"
                                            disabled={disableActions}
                                            style={{
                                                backgroundColor:
                                                    COLORS.PRIMARY.BLUE,
                                                borderColor:
                                                    COLORS.PRIMARY.BLUE,
                                            }}
                                        >
                                            შეკვეთა
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
}

// function Packages() {
//     return (
//         <ListGroup className="mb-3">
//             <ListGroup.Item
//                 active={true}
//                 type="button"
//                 action
//                 className="d-flex justify-content-between align-items-start"
//             >
//                 <div className="ms-2 me-auto">
//                     <div className="fw-bold">მაღალი გამავლობის</div>
//                     მხოლოდ გარედან გარეცხვა
//                 </div>
//                 <div
//                     style={{
//                         textAlign: "right",
//                     }}
//                 >
//                     asd
//                 </div>
//             </ListGroup.Item>
//         </ListGroup>
//     );
// }

// async function fetchServices() {
//     try {
//         const response = await (
//             await fetch(
//                 `${BASE_URL}/services?lang=${i18n.language || "ka"}`,
//                 {
//                     method: "GET",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             )
//         ).json();

//         if (response?.success && response?.data) setServices(response.data);
//     } catch (error) {
//         alert(error);
//     }
// }

{
    /* Vehicle type (required) */
}
{
    /* <ListGroup className="mb-3">
<Form.Label
    style={{
        color:
            isErrorVisible &&
            !form.size?.length
                ? COLORS.ADDITIONAL.RED
                : null,
    }}
>
    აირჩიე შენი ავტომობილის ტიპი
</Form.Label>
<ListGroup.Item
    active={form.size === "MEDIUM"}
    type="button"
    action
    onClick={() =>
        updateForm("size", "MEDIUM")
    }
    className="d-flex justify-content-between align-items-start"
>
    <img
        src="/images/sedan.png"
        style={{
            height: "50px",
            marginRight: "8px",
        }}
        alt="Sedan, Hatchback"
    />
    <div className="ms-2 me-auto">
        <div className="fw-bold">
            მსუბუქი ავტომობილი
        </div>
        მხოლოდ გარედან გარეცხვა
    </div>
    <div
        style={{
            textAlign: "right",
        }}
    >
        {discount.amount > 0 &&
            discount?.type ===
                DISCOUNT_TYPE.PERCENTAGE && (
                <>
                    <Badge
                        pill
                        style={{
                            fontSize:
                                "14px",
                        }}
                    >
                        -
                        {
                            discount.amount
                        }
                        %
                    </Badge>{" "}
                </>
            )}
        {discount.amount > 0 && (
            <>
                <Badge
                    bg="success"
                    pill
                    style={{
                        fontSize:
                            "14px",
                    }}
                >
                    <strike>
                        34 ₾
                    </strike>
                </Badge>{" "}
            </>
        )}
        <Badge
            bg="success"
            pill
            style={{ fontSize: "14px" }}
        >
            34 ₾
        </Badge>
    </div>
</ListGroup.Item>
<ListGroup.Item
    active={form.size === "LARGE"}
    type="button"
    action
    onClick={() =>
        updateForm("size", "LARGE")
    }
    className="d-flex justify-content-between align-items-start"
>
    <img
        src="/images/suv.png"
        style={{
            height: "50px",
            marginRight: "8px",
        }}
        alt="SUV, Crossover"
    />
    <div className="ms-2 me-auto">
        <div className="fw-bold">
            მაღალი გამავლობის
        </div>
        მხოლოდ გარედან გარეცხვა
    </div>
    <div
        style={{
            textAlign: "right",
        }}
    >
        {discount.amount > 0 &&
            discount?.type ===
                DISCOUNT_TYPE.PERCENTAGE && (
                <>
                    <Badge
                        pill
                        style={{
                            fontSize:
                                "14px",
                        }}
                    >
                        -
                        {
                            discount.amount
                        }
                        %
                    </Badge>{" "}
                </>
            )}
        {discount.amount > 0 && (
            <>
                <Badge
                    bg="success"
                    pill
                    style={{
                        fontSize:
                            "14px",
                    }}
                >
                    <strike>
                        39 ₾
                    </strike>
                </Badge>{" "}
            </>
        )}
        <Badge
            bg="success"
            pill
            style={{ fontSize: "14px" }}
        >
            39 ₾
        </Badge>
    </div>
</ListGroup.Item>
</ListGroup> */
}
