// import { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useAuth } from "./contexts/Authentication";
import { Routes, Route, Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Order from "./pages/Order";
import Orders from "./pages/Order/Orders";
import OrderDetails from "./pages/Order/OrderDetails";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./pages/ProtectedRoute";
import Loader from "./components/Loader";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function App() {
    // const { i18n } = useTranslation();
    const { loading } = useAuth();

    // useEffect(() => {
    //     try {
    //         const qsLang = new URLSearchParams(window.location.search).get(
    //             "lang"
    //         );
    //         if (i18n.language !== "ka" && i18n.language !== "en") {
    //             if (["en", "ka"].includes(qsLang)) {
    //                 i18n.changeLanguage(qsLang);
    //             } else {
    //                 i18n.changeLanguage("ka");
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [i18n]);

    if (loading) return <Loader godMode />;

    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="login" element={<Login />} />
                    <Route
                        path="order"
                        element={
                            <ProtectedRoute>
                                <Order />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="order/:id"
                        element={
                            <ProtectedRoute>
                                <OrderDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="orders"
                        element={
                            <ProtectedRoute>
                                <Orders />
                            </ProtectedRoute>
                        }
                    />

                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
            <div className="bubbles">
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
            </div>
        </div>
    );
}

function Layout() {
    return (
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

// function RequireAuth({ children }: { children: JSX.Element }) {
//     let auth = useAuth();
//     let location = useLocation();

//     if (!auth.user) {
//         // Redirect them to the /login page, but save the current location they were
//         // trying to go to when they were redirected. This allows us to send them
//         // along to that page after they login, which is a nicer user experience
//         // than dropping them off on the home page.
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     }

//     return children;
// }
