import { useNavigate } from "react-router-dom";
import { BASE_URL, COLORS, TIME_SLOTS } from "../../constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../contexts/Authentication";
import NotFound from "../../components/NotFound";
import Loader from "../../components/Loader";
import { useToasts } from "react-bootstrap-toasts";
import { getStatusLog } from "../../utils/statusLog.util";

export default function Orders() {
    const toasts = useToasts();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { token, services } = useAuth();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchOrders();
    }, []);

    async function fetchOrders() {
        try {
            const response = await (
                await fetch(
                    `${BASE_URL}/orders?lang=${i18n.language || "ka"}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
            ).json();

            if (response?.success && response?.data) setOrders(response.data);
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setLoading(false);
        }
    }

    if (!loading && !orders?.length) {
        return (
            <>
                <NotFound text="შეკვეთები არ გაქვს" />
                <div className="mt-4 text-center">
                    <Button
                        className="shadow-sm"
                        size="lg"
                        variant="primary"
                        style={{
                            width: "fit-content",
                            backgroundColor: COLORS.PRIMARY.TURQOISE,
                            borderColor: COLORS.PRIMARY.TURQOISE,
                        }}
                        onClick={() => navigate("/order")}
                    >
                        გამოიძახე 🚀
                    </Button>
                </div>
            </>
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Container className="mt-5">
            <h1 className="mb-4 fw-bold text-center">შეკვეთები</h1>

            <div className="mb-4 text-center">
                <Button
                    className="shadow-sm"
                    size="lg"
                    variant="primary"
                    style={{
                        width: "fit-content",
                        backgroundColor: COLORS.PRIMARY.TURQOISE,
                        borderColor: COLORS.PRIMARY.TURQOISE,
                    }}
                    onClick={() => navigate("/order")}
                >
                    გამოიძახე 🚀
                </Button>
            </div>

            <Row>
                <Col></Col>
                <Col md={12} lg={8}>
                    <ListGroup>
                        {orders.map((order) => (
                            <ListGroup.Item
                                action
                                onClick={() => navigate(`/order/${order._id}`)}
                                className="d-flex justify-content-between align-items-start"
                                style={
                                    order.completed
                                        ? {
                                            backgroundColor: "#d7f5d8",
                                        }
                                        : null
                                }
                            >
                                <div className="ms-2 me-auto">
                                    🚙 {order.make} {order.model}
                                    <br />
                                    🫧 {services.find(
                                        (service) =>
                                            service._id ===
                                            order.serviceId
                                    )?.title || ""}
                                    <br />
                                    📍 {order.address}
                                    <br />
                                    🗓{" "}
                                    {new Date(
                                        order.date
                                    ).toLocaleDateString()}{" "}
                                    ⏱{" "}
                                    {
                                        TIME_SLOTS.find(
                                            (i) => i.value === order.time
                                        ).name
                                    }
                                    <br />
                                    <div className="fw-bold">
                                        სტატუსი:{" "}
                                        {
                                            getStatusLog(order)
                                                ?.filter(
                                                    (item) => item.isComplete
                                                )
                                                ?.at(-1)?.label
                                        }
                                        {/* {!order.paid
                                            ? "გადახდის მოლოდინში"
                                            : null}
                                        {order.completed ? "დასრულებული" : null}
                                        {order.paid && !order.completed
                                            ? "დამუშავების პროცესში"
                                            : null} */}
                                        {/* {statusToTextMap(
                                            order?.statusLog[
                                                order?.statusLog?.length - 1
                                            ]?.status
                                        )} */}
                                    </div>
                                </div>
                                <Badge
                                    bg={order.paid ? "success" : "danger"}
                                    pill
                                    style={{ fontSize: "14px" }}
                                >
                                    {order.price} ₾
                                </Badge>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );

    // return (
    //     <div>
    //         <Container className="mt-5">
    //             <h2 className="mb-4 fw-bold text-center">შეკვეთის დეტალები</h2>

    //             <Row>
    //                 <Col></Col>
    //                 <Col md={12} lg={5}>
    //                     <Card bg="light" text="dark" className="mb-3">
    //                         <Alert
    //                             key="danger"
    //                             variant="danger"
    //                             className="text-center"
    //                         >
    //                             გადაიხადე მომსახურების საფასური იმისთვის რომ
    //                             მოხდეს შეკვეთის შესრულება
    //                         </Alert>
    //                         <Card.Body>
    //                             <Row xs={1} md={1} className="g-4">
    //                                 <Col>
    //                                     <Card
    //                                         bg="light"
    //                                         text="dark"
    //                                         className="mb-3"
    //                                     >
    //                                         <Card.Header>
    //                                             <Row>
    //                                                 <Col>
    //                                                     {
    //                                                         order.service._id
    //                                                             .title.ka
    //                                                     }
    //                                                 </Col>
    //                                                 <Col className="text-end fw-bold text-danger">
    //                                                     გადასახდელი
    //                                                 </Col>
    //                                             </Row>
    //                                         </Card.Header>
    //                                         <Card.Body>
    //                                             <Card.Text>
    //                                                 გარეცხვის საშუალო დრო:{" "}
    //                                                 {
    //                                                     order.service._id
    //                                                         .avgWashMinutes
    //                                                 }{" "}
    //                                                 წთ
    //                                             </Card.Text>
    //                                         </Card.Body>
    //                                         <Card.Footer>
    //                                             <Row>
    //                                                 <Col className="d-flex align-items-center">
    //                                                     {
    //                                                         order.payment
    //                                                             .finalPrice
    //                                                     }{" "}
    //                                                     {order.payment.currency}
    //                                                 </Col>
    //                                                 <Col className="text-end">
    //                                                     <Button
    //                                                         variant="success"
    //                                                         className="fw-bold"
    //                                                     >
    //                                                         გადახდა
    //                                                     </Button>
    //                                                 </Col>
    //                                             </Row>
    //                                         </Card.Footer>
    //                                     </Card>
    //                                 </Col>
    //                             </Row>

    //                             <StepperComponent
    //                                 stepperContent={[
    //                                     {
    //                                         label: `შეკვეთა შეიქმნა`,
    //                                         comment:
    //                                             order.statusLog?.[0]?.timestamp?.slice(
    //                                                 0,
    //                                                 10
    //                                             ),
    //                                         isError: false,
    //                                         isComplete: true,
    //                                     },
    //                                     {
    //                                         label: "გადახდა",
    //                                         isError: false,
    //                                         isComplete: false,
    //                                     },
    //                                     {
    //                                         label: "შეკვეთა გააქტიურდა",
    //                                         isError: false,
    //                                         isComplete: false,
    //                                     },
    //                                     {
    //                                         label: "დაიწყო გარეცხვა",
    //                                         isError: false,
    //                                         isComplete: false,
    //                                     },
    //                                     {
    //                                         label: "დასრულდა გარეცხვა",
    //                                         isError: false,
    //                                         isComplete: false,
    //                                     },
    //                                     {
    //                                         label: "დაიხურა შეკვეთა ",
    //                                         isError: false,
    //                                         isComplete: false,
    //                                     },
    //                                 ]}
    //                             />

    //                             {/* <div className="d-grid gap-2 mb-1 mt-3 shadow-sm">
    //                                 <Button
    //                                     variant="primary"
    //                                     size="lg"
    //                                     type="submit"
    //                                     style={{
    //                                         backgroundColor:
    //                                             COLORS.PRIMARY.BLUE,
    //                                         borderColor: COLORS.PRIMARY.BLUE,
    //                                     }}
    //                                     disabled={
    //                                         !make.length ||
    //                                         !type.length ||
    //                                         !licensePlate.length ||
    //                                         !address.length ||
    //                                         !date.length ||
    //                                         !firstName.length ||
    //                                         !acceptedTerms
    //                                     }
    //                                 >
    //                                     გაგრძელება
    //                                 </Button>
    //                             </div> */}
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={12} lg={5}>
    //                     <Card bg="light" text="dark" className="mb-3">
    //                         <Card.Body>
    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>მწარმოებელი</Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={order.car.make}
    //                                 />
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>მოდელი</Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={order.car.model}
    //                                 />
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>სახელმწიფო სანომრე ნიშანი</Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={order.car.licensePlate}
    //                                 />
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>ტიპი</Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={CAR_SIZE[order.car.size]}
    //                                 />
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>მისამართი:</Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={order.location.address}
    //                                 />
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>თარიღი და დრო:</Form.Label>
    //                                 <Form.Control
    //                                     className="mb-3"
    //                                     disabled
    //                                     type="date"
    //                                     value={order.appointment.date.slice(
    //                                         0,
    //                                         10
    //                                     )}
    //                                 />
    //                                 <ButtonGroup>
    //                                     {TIME_SLOTS.map((item) => (
    //                                         <ToggleButton
    //                                             key={item.value}
    //                                             disabled
    //                                             id={item.value}
    //                                             variant="outline-secondary"
    //                                             type="radio"
    //                                             name="radio"
    //                                             value={item.value}
    //                                             checked={
    //                                                 order.appointment.time ===
    //                                                 item.value
    //                                             }
    //                                         >
    //                                             {
    //                                                 item.translation[
    //                                                     i18n.language
    //                                                 ]
    //                                             }
    //                                         </ToggleButton>
    //                                     ))}
    //                                 </ButtonGroup>
    //                             </Form.Group>

    //                             <Form.Group className="mb-3">
    //                                 <Form.Label>
    //                                     კომენტარი / დამატებითი ინსტრუქცია:
    //                                 </Form.Label>
    //                                 <Form.Control
    //                                     disabled
    //                                     type="text"
    //                                     value={order.appointment.comment}
    //                                 />
    //                             </Form.Group>

    //                             {/* <div className="d-grid gap-2 mb-1 mt-3 shadow-sm">
    //                                 <Button
    //                                     variant="primary"
    //                                     size="lg"
    //                                     type="submit"
    //                                     style={{
    //                                         backgroundColor:
    //                                             COLORS.PRIMARY.BLUE,
    //                                         borderColor: COLORS.PRIMARY.BLUE,
    //                                     }}
    //                                     disabled={
    //                                         !make.length ||
    //                                         !type.length ||
    //                                         !licensePlate.length ||
    //                                         !address.length ||
    //                                         !date.length ||
    //                                         !firstName.length ||
    //                                         !acceptedTerms
    //                                     }
    //                                 >
    //                                     გაგრძელება
    //                                 </Button>
    //                             </div> */}
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col></Col>
    //             </Row>
    //         </Container>
    //     </div>
    // );
}
