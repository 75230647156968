import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastsProvider as BootstrapToastsProvider } from "react-bootstrap-toasts";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import translations from "./translations.json";
import App from "./App";
import { AuthProvider } from "./contexts/Authentication";
import ScrollToTop from "./components/ScrollToTop";

i18n.use(initReactI18next).init({
    lng: "ka",
    resources: translations,
    interpolation: {
        escapeValue: false,
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BootstrapToastsProvider
            toastContainerProps={{ position: "bottom-end" }}
            limit={3}
        >
            <AuthProvider>
                <BrowserRouter>
                    <App />
                    <ScrollToTop />
                </BrowserRouter>
            </AuthProvider>
        </BootstrapToastsProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <Home />,
//         errorElement: <NotFound />,
//     },
//     {
//         path: "/coming-soon",
//         element: <ComingSoon />,
//     },
//     {
//         path: "/login",
//         element: <Login />,
//     },
//     {
//         path: "/order",
//         element: (
//             <ProtectedRoute>
//                 <Order />
//             </ProtectedRoute>
//         ),
//     },
//     // {
//     //     path: "/order/:id",
//     //     element: (
//     //         <ProtectedRoute>
//     //             <OrderDetails />
//     //         </ProtectedRoute>
//     //     ),
//     // },
//     // {
//     //     path: "/privacy-policy",
//     //     element: <PrivacyPolicy />,
//     // },
//     // {
//     //     path: "/terms-and-conditions",
//     //     element: <TermsAndConditions />,
//     // },
// ]);
