export const STATUS_TO_TRANSLATION = {
    REQUESTED: "განთავსდა",
    PAYMENT_FAILED: "წარუმატებელი გადახდა",
    PAID: "გადახდილია",
    ASSIGNED: "შეკვეთა მიღებულია",
    IN_PROGRESS: "მიმდინარეობს გარეცხვა",
    COMPLETED: "გარეცხილია",
    FEEDBACK: "შეფასება",
    REJECTED: "შეკვეთა უარყოფილია",
    CANCELED: "შეკვეთა გაუქმდა",
    REFUNDED: "თანხა დაბრუნდა",
};

const defaultStatusLog = [
    {
        label: STATUS_TO_TRANSLATION["REQUESTED"],
    },
    {
        label: STATUS_TO_TRANSLATION["PAID"],
    },
    {
        label: STATUS_TO_TRANSLATION["ASSIGNED"],
    },
    {
        label: STATUS_TO_TRANSLATION["IN_PROGRESS"],
    },
    {
        label: STATUS_TO_TRANSLATION["COMPLETED"],
    },
    {
        label: STATUS_TO_TRANSLATION["FEEDBACK"],
    },
];

export function getStatusLog(order) {
    const newStatusLog = [];

    order.statusLog
        .filter((item) => {
            // If order is paid and historically there was a failed payment - filter out such statuses
            if (order.paid && item.status === "PAYMENT_FAILED") {
                return false;
            }
            return true;
        })
        .forEach((item) => {
            // When payment fails - display accordingly
            if (item.status === "PAYMENT_FAILED" && !order.paid) {
                return newStatusLog.push({
                    label: STATUS_TO_TRANSLATION[item.status],
                    comment: `${new Date(
                        item.timestamp
                    ).toLocaleDateString()} ${new Date(
                        item.timestamp
                    ).toLocaleTimeString()}`,
                    isError: true, // Todo: cross icon instead of number
                });
            }

            newStatusLog.push({
                label: STATUS_TO_TRANSLATION[item.status],
                comment: `${new Date(
                    item.timestamp
                ).toLocaleDateString()} ${new Date(
                    item.timestamp
                ).toLocaleTimeString()}`,
                isComplete: true,
            });
        });

    const failedPaymentsCount =
        order.statusLog.filter((item) => item.status === "PAYMENT_FAILED")
            ?.length || 0;

    // If order is canceled, rejected or refunded - don't show upcoming steps
    if (
        order.statusLog.find((item) =>
            ["REJECTED", "CANCELED", "REFUNDED"].includes(item.status)
        )
    ) {
        return newStatusLog;
    }

    return newStatusLog.concat(
        defaultStatusLog.slice(
            newStatusLog.length - failedPaymentsCount,
            defaultStatusLog.length
        )
    );
    // isActive: false,
    // isWarning: false,
    // isError: false,
    // isComplete: false,
}
